@use '@angular/material' as mat;
@use '@material/textfield';

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "ngx-toastr/toastr";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
  --geb-green00: #00968a;
  --geb-green01: #00786e;
  --geb-red: #d8222a;
  --geb-black: #000000;
  --geb-gray08: #222222;
  --geb-gray07: #555555;
  --geb-gray06: #777777;
  --geb-gray05: #9c9c9c;
  --geb-gray04: #bababa;
  --geb-gray03: #d4d4d4;
  --geb-gray02: #f3f3f3;
  --geb-gray01: #fafafa;
  --geb-white: #ffffff;
}

@font-face {
  font-family: 'ClearSans';
  src: url('/assets/fonts/ClearSans/ClearSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ClearSans';
  src: url('/assets/fonts/ClearSans/ClearSans-Italic.woff') format('woff');
  font-style: italic;
}

@font-face {
  font-family: 'ClearSans';
  src: url('/assets/fonts/ClearSans/ClearSans-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'ClearSans';
  src: url('/assets/fonts/ClearSans/ClearSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ClearSansMedium';
  src: url("/assets/fonts/ClearSans/ClearSans-Medium.woff") format('woff');
}

html, body {
  font-family: ClearSans, Arial, sans-serif !important;
  height: 100vh;
  overflow: hidden;
}

.gap {
  padding-top: 20px;
}

a {
  cursor: pointer;
}

/*Für Datepicker Popup*/
.owl-dt-popup {
  top: 86px !important;
  left: auto !important;
  right: 0 !important;
  overflow: hidden;
}

@keyframes blink {
    50% {
      border: 1px solid #ff0000;
    }
}

.dense-min {
    @include mat.all-component-densities(minimum);
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-floating-label {
  display: inline !important;
}

.mat-mdc-snack-bar-container {
    &.success-dialog-snackbar {
        --mat-mdc-snack-bar-button-color: #35caa5;
    }
}
